<template>
    <div class="addressBox">
        <!-- <myAccount></myAccount> -->
        <div class="account-address-book account-section">
            <div class="smartphone-back-button">
                <router-link :to="{ path: '/myAccount' }">
                    <span class="icon"></span>
                    <span>Back</span>
                </router-link>
                <p>Save all your delivery details to have them at hand when you order.</p>
            </div>

            <!--      <div class="addressesCount zeroAddresses">-->
            <!--        You have not saved a shipping address yet.-->
            <!--      </div>-->
            <!--      <div class="addressesCount Addresses">-->
            <!--        You have 1 saved address.-->
            <!--      </div>-->
            <div>
                <div
                    v-for="(address, index) in addressList"
                    role="row"
                    class="addressEntry is-billing-default country-gw"
                >
                    <div role="rowheader" class="address-wrapper">
                        <div class="default-address"
                             v-if="defaultShippingAddressId && defaultShippingAddressId === address.id">
                            <span class="text"> Default shipping address </span>
                        </div>
                        <div class="name">
                            <!--firstname-->
                            <span class="value" data-sensitive="true">{{ address.firstName }}.</span>
                            <!--lastname-->
                            <span class="value" data-sensitive="true">{{ address.lastName }}</span>
                        </div>
                        <div class="streetWithNumber">
                            <!--address-->
                            <span class="value" data-sensitive="true">{{ address.street }}</span>
                        </div>
                        <div class="city">
                            <!--city-->
                            <span class="value" data-sensitive="true">{{ address.province }}</span>
                            <!--              <span class="value" data-sensitive="true">XXXXX </span>-->
                            <!--province-->
                            <span class="value" data-sensitive="true">{{ address.city }}</span>
                            <!--country-->
                            <!--              <span class="value">GW </span>-->
                            <!--              <a href="#"><span class="messageInfo">info</span></a>-->
                        </div>
                        <div class="mail">
                            <span class="value" data-sensitive="true">{{ address.email }}</span>
                        </div>
                        <div class="phone">
                            <span class="value" data-sensitive="true">{{ address.mobile }}</span>
                        </div>
                        <div class="mobile-num">
                            <span class="value" data-sensitive="true">{{ address.tel }}</span>
                        </div>
                    </div>
                    <div role="cell">
                        <span class="messageInfo" @click="toModifyAddress(address)">Modify</span>
                        <span v-html="'&emsp;&emsp;&emsp;'"></span>
                        <span class="messageInfo" @click="toDeleteAddress(address.id)">Delete</span>
                    </div>
                </div>
            </div>
            <div class="account-address-book account-section-full-width">
                <button @click="addDrawer">Add a new address</button>
            </div>
        </div>
        <el-drawer
            :modal="false"
            :visible.sync="newAddress"
            size="100%"
            direction="btt"
        >
            <div class="addressDraer">
                <div class="mfp-container mfp-inline-holder">
                    <div class="mfp-content">
                        <div class="inner mfp-content-scrollable-area">
                            <div class="mfp-content-flex-item">
                                <div class="mfp-content-holder">
                                    <el-form
                                        ref="registerForm"
                                        :rules="addressRules"
                                        :label-position="labelPosition"
                                        :model="registerForm"
                                        class="demo-ruleForm"
                                        size="small"
                                        :hide-required-asterisk="true"
                                    >
                                        <h2 class="layer-title" v-show="addressStatus === 0">New address</h2>
                                        <h2 class="layer-title" v-show="addressStatus === 1">Modify this address</h2>
                                        <div class="mandatory">* Required fields</div>
                                        <div class="serverSideErrors"></div>
                                        <div class="Name">
                                            <el-form-item label="First Name *" prop="firstName">
                                                <el-input
                                                    v-model="registerForm.firstName"
                                                    placeholder="First Name"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="Surname">
                                            <el-form-item label="Last Name *" prop="lastName">
                                                <el-input
                                                    v-model="registerForm.lastName"
                                                    placeholder="Last Name"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="Address">
                                            <el-form-item label="Address *" prop="street">
                                                <el-input
                                                    v-model="registerForm.street"
                                                    placeholder="Address"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="CareOf">
                                            <el-form-item label="C/O " prop="co">
                                                <el-input
                                                    v-model="registerForm.co"
                                                    placeholder="C/O"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="City">
                                            <el-form-item label="City *" prop="city">
                                                <el-input
                                                    v-model="registerForm.city"
                                                    placeholder="City"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="Province">
                                            <el-form-item label="State/Province *" prop="province">
                                                <el-input
                                                    v-model="registerForm.province"
                                                    placeholder="State/Province"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="countryField">
                                            <el-form-item label="Country/Region" prop="country">
                                                <el-input
                                                    v-model="registerForm.country"
                                                    placeholder="Country/Region"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="Mail">
                                            <el-form-item label="Email address *" prop="email">
                                                <el-input
                                                    v-model="registerForm.email"
                                                    placeholder="Email address"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="Phone">
                                            <el-form-item label="Phone Number *" prop="mobile">
                                                <!--                        <el-select-->
                                                <!--                          v-model="value"-->
                                                <!--                          placeholder="请选择"-->
                                                <!--                          style="width: 100px"-->
                                                <!--                        >-->
                                                <!--                          <el-option-->
                                                <!--                            v-for="item in options"-->
                                                <!--                            :key="item.value"-->
                                                <!--                            :label="item.label"-->
                                                <!--                            :value="item.value"-->
                                                <!--                          >-->
                                                <!--                          </el-option>-->
                                                <!--                        </el-select>-->
                                                <el-input
                                                    v-model="registerForm.mobile"
                                                    placeholder="Phone Number"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="Mobile">
                                            <el-form-item label="Cell phone " prop="tel">
                                                <!--                        <el-select-->
                                                <!--                          v-model="value"-->
                                                <!--                          placeholder="请选择"-->
                                                <!--                          style="width: 100px"-->
                                                <!--                        >-->
                                                <!--                          <el-option-->
                                                <!--                            v-for="item in options"-->
                                                <!--                            :key="item.value"-->
                                                <!--                            :label="item.label"-->
                                                <!--                            :value="item.value"-->
                                                <!--                          >-->
                                                <!--                          </el-option>-->
                                                <!--                        </el-select>-->
                                                <el-input
                                                    v-model="registerForm.tel"
                                                    placeholder="Cell phone"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="IsDefault">
                                            <el-checkbox :checked="registerForm.isDefaultShippingAddress"
                                                         v-model="registerForm.isDefaultShippingAddress"
                                            >Default shipping address
                                            </el-checkbox
                                            >
                                        </div>
                                        <!--                    <div class="IsBillingDefault">-->
                                        <!--                      <el-checkbox v-model="registerForm.isDefaultBillAddress"-->
                                        <!--                        >Default billing address</el-checkbox-->
                                        <!--                      >-->
                                        <!--                    </div>-->
                                        <p class="privacyDisclaimer">
                                            The personal data you have entered will be used by BING XU
                                            to expedite the purchasing process. <br/>Please note that
                                            you are responsible for third party data that you disclose
                                            to BING XU<br/>
                                            Your data is safe with BING XU.<br/>Read the
                                            <router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link> for further information.
                                        </p>
                                        <div class="buttonsRow">
                                            <button type="button" class="submit" @click="save('registerForm')">
                                                Save
                                            </button>
                                            <button type="button" class="closeBtn" @click="cancel">
                                                Cancel
                                            </button>
                                        </div>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
        <el-drawer
            :modal="false"
            :visible.sync="addAddress"
            size="80%"
            direction="btt"
        >
            <div>Your address has been successfully saved</div>
        </el-drawer>
        <el-drawer
            :modal="false"
            :visible.sync="deleteAddress"
            size="80%"
            direction="btt"
            class="drawer"
        >
            <div class="mfp-content-flex-item">
                <div class="mfp-content-holder">
                    <div v-show="deleteStatus===0">
                        <p class="delete-message">Are you sure you want to delete this address?</p>
                        <div class="buttonsRow modify">
                            <button type="button" class="proceed" data-ytos-action="confirm"
                                    @click="confirmDelete(currentDeleteId)">
                                <span class="text">Confirm</span>
                            </button>
                            <button type="button" class="cancel" data-ytos-action="cancel" @click="cancelDelete">
                                <span class="text">Cancel</span>
                            </button>
                        </div>
                    </div>
                    <p class="delete-message" v-show="deleteStatus===1">
                        Address deleted
                    </p>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
// import myAccount from "@/views/myAccount";
import {addressAddSave, removeAddress} from "@/api/api";
import {getAddress} from "../api/api";

export default {
    name: "myAddress",
    components: {
        // myAccount
    },
    data() {
        return {
            currentDeleteId: null,
            addressList: [],
            defaultShippingAddressId: null,
            newAddress: false,
            addAddress: false,
            deleteAddress: false,
            labelPosition: "top",
            checkList1: "",
            checkList2: "",
            value: "",
            addressStatus: 0,
            deleteStatus: 0,
            addressRules: {
                firstName: [
                    {
                        required: true,
                        message: "The name field is required",
                        trigger: "blur"
                    }
                ],
                lastName: [
                    {
                        required: true,
                        message: "The last name field is required",
                        trigger: "blur"
                    }
                ],
                street: [
                    {
                        required: true,
                        message: "The address field is required",
                        trigger: "blur"
                    }
                ],
                city: [
                    {
                        required: true,
                        message: "The city field is required",
                        trigger: "blur"
                    }
                ],
                province: [
                    {
                        required: true,
                        message: "The province field is required",
                        trigger: "blur"
                    }
                ],
                email: [
                    {
                        required: true,
                        message: "The email field is required",
                        trigger: "blur"
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: "The telephone number field is required",
                        trigger: "blur"
                    }
                ]
            },
            registerForm: {
                firstName: "",
                lastName: "",
                email: "",
                province: "",
                city: "",
                county: "",
                street: "",
                mobile: "",
                tel: "",
                country: "",
                co: "",
                isDefaultShippingAddress: false,
                isDefaultBillAddress: false
            }
        };
    },
    mounted() {
        this.fetchAddress();
    },
    methods: {
        async fetchAddress() {
            const res = await getAddress();
            if (res.code === 0) {
                this.addressList = res.data.list;
                this.defaultShippingAddressId = res.data.defaultShippingAddressId;
            }
        },
        addDrawer() {
            //添加新地址时将表单内容重置成默认
            this.registerForm = {
                firstName: "",
                lastName: "",
                email: "",
                province: "",
                city: "",
                county: "",
                street: "",
                mobile: "",
                tel: "",
                country: "",
                co: "",
                isDefaultShippingAddress: false,
                isDefaultBillAddress: false
            };
            this.addressStatus = 0;
            this.newAddress = true;
        },
        toModifyAddress(item) {
            //编辑地址时，将表单内容填充为当前修改的条目
            this.registerForm = item;
            this.addressStatus = 1;
            this.newAddress = true;
        },
        toDeleteAddress(id) {
            this.deleteStatus = 0;
            this.currentDeleteId = id;
            this.deleteAddress = true;
        },
        async confirmDelete(id) {
            const res = await removeAddress(id);
            const {code, message} = res;
            if (code === 0) {
                this.deleteStatus = 1;
                this.fetchAddress();
                this.$message({
                    showClose: true,
                    message: "Success",
                    type: "success"
                });
                setTimeout(() => {
                    this.deleteAddress = false;
                }, 1000);
            } else {
                this.$message({
                    showClose: true,
                    message: message,
                    type: "error"
                });
            }
        },
        cancelDelete() {
            this.currentDeleteId = null;
            this.deleteAddress = false;
        },
        save(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const res = await addressAddSave({address: this.registerForm});
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.newAddress = false;
                        this.$message({
                            showClose: true,
                            message: "Success",
                            type: "success"
                        });
                    }
                    this.fetchAddress();
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        cancel() {
            this.newAddress = false;
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    line-height: 30px;
    word-break: break-all;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    word-break: break-all;
}

::v-deep .el-checkbox__inner {
    border-color: #212121;
    width: 0.5rem;
    height: 0.5rem;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #212121;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #212121;
    border: none;
}

::v-deep .el-checkbox__input {
    vertical-align: top;
    line-height: 2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    display: none;
}

::v-deep .el-checkbox {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid #d8d7d5;
}

::v-deep .el-form-item__label {
    padding: 0;
    line-height: 0.1rem;
}

::v-deep .el-select {
    width: 100%;
}

::v-deep .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep .el-tabs__nav-scroll {
    display: flex;
}

::v-deep .el-tabs__header {
    margin: 0;
    padding: 1rem 0;
}

::v-deep .el-tabs__item {
    height: 0.8rem;
    line-height: 0;
    font-size: inherit;
}

::v-deep .el-tabs__item:hover {
    color: inherit;
}

::v-deep .el-tabs__active-bar {
    background: #212121;
    height: 1px;
}

::v-deep .el-tabs__item {
    font-weight: 400;
}

::v-deep .el-tabs__item.is-active {
    color: #212121;
}

a {
    text-decoration: none;
    color: inherit;
}

h1,
h2,
h3 {
    font-weight: 400;
    font-size: 1em;
}

.account-address-book {
    button {
        background-color: #212121;
        border-width: 0;
        color: #fff;
        display: block;
        padding: 1rem 3.125rem 0.9375rem;
        text-align: center;
        width: 100%;
        position: relative;
        margin-top: 2rem;
    }
}

.account-address-book {
    margin-top: 6rem;
}

.addressesCount .zeroAddresses {
}

.addressesCount .Addresses {
}

.addressEntry .address-wrapper .default-address {
    font-style: italic;
    margin-bottom: 1rem;
    order: 0;
    width: 100%;
}

.addressEntry {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: 1px solid #d8d7d5;
    margin-top: 3rem;
}

.default-address {
    font-style: italic;
}

.messageInfo {
    //border-bottom: 1px solid #000;
    cursor: pointer;
}

.value {
    line-height: 1.2rem;
}

.mfp-content-flex-item {
    margin: auto;
    max-width: 100%;
    width: 100%;
}

.mfp-content-holder {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s cubic-bezier(.22, .61, .36, 1) 0s, opacity .7s cubic-bezier(.22, .61, .36, 1) 0s;
    background: #fff;
    padding: 1.9375rem 2rem;
}

.buttonsRow {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.buttonsRow .proceed, .buttonsRow .cancel, .buttonsRow .submit, .buttonsRow .close, .buttonsRow .closeBtnBottom {
    position: relative;
    background-color: #212121;
    border-width: 0;
    color: #fff;
    display: block;
    padding: 1rem 3.125rem .9375rem;
    text-align: center;
    width: 100%;
    order: 1;
}

.buttonsRow button, .buttonsRow input {
    margin-left: .3125rem;
    margin-right: .3125rem;
}

.buttonsRow button, .buttonsRow input, .fieldRow {
    margin-bottom: 2rem;
}

[type=button], [type=submit], button {
    cursor: pointer;
}

.buttonsRow .cancel, .buttonsRow .close, .buttonsRow .closeBtnBottom {
    background-color: #e1e0de;
    border-width: 0;
    color: #212121;
    display: block;
    padding: 1rem 3.125rem .9375rem;
    text-align: center;
    width: 100%;
    order: 2;
}

.delete-message {
    text-align: center;
    margin-bottom: 2rem;
}

.addressDraer {
    .mfp-container {
        display: flex;
        min-height: 100%;
        flex-direction: column;

        .inner {
            width: 100%;
            height: 100%;
            display: flex;
            position: relative;
            flex-grow: 1;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
            overflow-x: hidden;
            overflow-y: auto;

            .mfp-content-flex-item {
                //width: 51.875rem;
                form {
                    //.Surname,.SurnameJp{
                    //  margin-bottom: 2rem;
                    //}

                    .CareOf,
                    .privacyDisclaimer {
                        margin-bottom: 2rem;
                    }

                    .buttonsRow {
                        margin-top: 2rem;

                        button {
                            margin-left: 0.3125rem;
                            margin-right: 0.3125rem;
                        }
                    }

                    .layer-title {
                        margin: 0;
                        clip: auto;
                        width: auto;
                        height: auto;
                        overflow: visible;
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 2;
                        grid-column-end: 3;
                        -ms-grid-row: 1;
                        grid-row-start: 1;
                        -ms-grid-row-span: 1;
                        grid-row-end: 2;
                    }

                    .mandatory {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 2;
                        grid-row-start: 2;
                        -ms-grid-row-span: 1;
                        grid-row-end: 3;
                    }

                    .serverSideErrors {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 2;
                        grid-row-start: 2;
                        -ms-grid-row-span: 1;
                        grid-row-end: 3;
                    }

                    .Name {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 3;
                        grid-row-start: 3;
                        -ms-grid-row-span: 1;
                        grid-row-end: 4;
                    }

                    .Surname {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 3;
                        grid-row-start: 3;
                        -ms-grid-row-span: 1;
                        grid-row-end: 4;
                    }

                    .SurnameJp {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 3;
                        grid-row-start: 3;
                        -ms-grid-row-span: 1;
                        grid-row-end: 4;
                    }

                    .Address {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 4;
                        grid-row-start: 4;
                        -ms-grid-row-span: 1;
                        grid-row-end: 5;
                    }

                    .CareOf {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 4;
                        grid-row-start: 4;
                        -ms-grid-row-span: 1;
                        grid-row-end: 5;
                    }

                    .City {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 5;
                        grid-row-start: 5;
                        -ms-grid-row-span: 1;
                        grid-row-end: 6;
                    }

                    .countryField {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 6;
                        grid-row-start: 6;
                        -ms-grid-row-span: 1;
                        grid-row-end: 7;
                    }

                    .Province {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 6;
                        grid-row-start: 6;
                        -ms-grid-row-span: 1;
                        grid-row-end: 7;
                    }

                    .Mail {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 7;
                        grid-row-start: 7;
                        -ms-grid-row-span: 1;
                        grid-row-end: 8;
                    }

                    .Phone {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 7;
                        grid-row-start: 7;
                        -ms-grid-row-span: 1;
                        grid-row-end: 8;

                        ::v-deep .el-form-item__content {
                            display: flex;
                        }
                    }

                    .Mobile {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 8;
                        grid-row-start: 8;
                        -ms-grid-row-span: 1;
                        grid-row-end: 9;

                        ::v-deep .el-form-item__content {
                            display: flex;
                        }
                    }

                    .IsDefault {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 8;
                        grid-row-start: 8;
                        -ms-grid-row-span: 1;
                        grid-row-end: 9;
                    }

                    .IsBillingDefault {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 9;
                        grid-row-start: 9;
                        -ms-grid-row-span: 1;
                        grid-row-end: 10;
                    }

                    .privacyDisclaimer {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 10;
                        grid-row-start: 10;
                        -ms-grid-row-span: 1;
                        grid-row-end: 11;
                    }

                    .buttonsRow {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 11;
                        grid-row-start: 11;
                        -ms-grid-row-span: 1;
                        grid-row-end: 12;
                    }

                    .privacyDisclaimer {
                        line-height: 1.1875rem;

                        a {
                            cursor: pointer;
                            border-bottom: 1px solid #212121;
                            padding-bottom: 0.2rem;
                        }
                    }

                    .submit {
                        background-color: #212121;
                        border-width: 0;
                        color: #fff;
                        display: block;
                        //padding: 1rem 3.125rem .9375rem;
                        text-align: center;
                        width: 100%;
                        order: 1;
                        margin-right: 0.3125rem;
                    }

                    .closeBtn {
                        background-color: #e1e0de;
                        border-width: 0;
                        color: #212121;
                        display: block;
                        //padding: 1rem 3.125rem .9375rem;
                        text-align: center;
                        width: 100%;
                        order: 2;
                        margin-left: 0.3125rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .buttonsRow .proceed, .buttonsRow .submit, .buttonsRow .cancel, .buttonsRow .close, .buttonsRow .closeBtnBottom {
        max-width: 18.75rem;
        padding: .3125rem .3125rem .375rem;
    }
    .buttonsRow {
        align-items: flex-start;
        flex-direction: row;
    }
    .account-address-book .addressList .addressEntry:last-child, .account-address-book .addressList .addressEntry:nth-last-child(2) {
        border-bottom: 1px solid #d8d7d5;
    }

    .addressEntry {
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #d8d7d5;
    }
    .addressEntry .address-wrapper {
        margin-bottom: 0;
        width: auto;
        max-width: 15rem;
    }
    .account-section {
        margin: 0 auto;
        max-width: 100%;
        width: 25rem;
    }
    .account-address-book {
        button {
            max-width: none;
            width: 23.4375rem !important;
            margin-left: 0;
            margin-right: 0;
            margin-top: 4rem;
            padding: 0.3125rem 0.3125rem 0.375rem;
        }
    }
    .addressDraer {
        .mfp-content-flex-item {
            margin: 0 auto;
            max-width: 100%;
            //width: 25rem;
            width: 51.875rem;

            form {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 23.4375rem 23.4375rem;
                grid-template-columns: 23.4375rem 23.4375rem;
                -ms-grid-rows: 3.125rem 5rem 5rem 5rem 5rem 5rem 5rem 3.125rem 3.125rem 9.375rem 5rem;
                grid-template-rows: 3.125rem 5rem 5rem 5rem 5rem 5rem 5rem 3.125rem 3.125rem 9.375rem 5rem;
                grid-column-gap: 5rem;

                .buttonsRow {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;

                    button {
                        padding: 0.3125rem 0.3125rem 0.375rem;
                    }

                    button:hover {
                        cursor: pointer;
                    }

                    .submit {
                        max-width: 18.75rem;
                        margin-left: 0 !important;
                    }

                    .closeBtn {
                        max-width: 18.75rem;
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 1280px) {

    .drawer {
        height: 100%;
    }
    .uiLayer-confirmationAlert .mfp-content-flex-item, .uiLayer-confirmLayer .mfp-content-flex-item {
        width: 46.875rem;
    }
    .uiLayer-confirmLayer .buttonsRow {
        margin: 0 -3.125rem;
    }
    .mfp-content-holder {
        padding: 3.9375rem calc(4% + .8125rem);
    }
    .addressEntry {
        width: 23.4375rem;
    }
    .account-section {
        width: 51.875rem;
        margin: 0 auto;

        .account-overview-section {
            width: 23.4375rem;
        }
    }
    .smartphone-back-button {
        display: none;
    }
    .addressDraer {
        .mfp-content {
            min-height: 82vh;
            flex-grow: 0;

            form {
                .Name,
                .Address,
                .City,
                .Province,
                .Mail,
                .Mobile {
                    margin-right: 2.5rem;
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .delete-message {
        text-align: center;
        margin-bottom: 2rem;
    }
    .buttonsRow.modify {
        margin: 0 -3.125rem;
    }

    .addressBox {
        .main {
            display: none;
        }

        .account-address-book {
            margin-top: 3.125rem;
        }

        .account-address-book .addressesCount, .account-address-book p, .account-section-full-width, .addressEntry .address-wrapper, .addressEntry .address-wrapper + div {
            margin-left: 1.1875rem;
            margin-right: 1.1875rem;
        }

        .addressEntry .address-wrapper + div {
            display: flex;
            right: 0;
            flex-direction: column;
        }

        .smartphone-back-button {
            display: block;
            padding-top: 2rem;
            margin-bottom: 2rem;
            border-top: 1px solid #d8d7d5;

            a {
                margin-left: 1.1875rem;
                position: relative;

                .icon {
                    width: 0.5rem;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }

                .icon:before {
                    background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-left.svg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    content: "";
                    padding: 0 0 100%;
                    display: block;
                }

                span:nth-child(2) {
                    padding-left: 0.9375rem;
                }
            }
        }

        .addressesCount,
        .account-section-full-width {
            margin: 0 1.1875rem;
        }

        .addressesCount {
            margin-top: 1.5rem;
        }
    }
    ::v-deep .v-modal {
        display: none !important;
        top: 3.125rem !important;
    }
    .addressBox {
        margin-top: 3.125rem;

        ::v-deep .el-drawer__wrapper {
            top: 3.125rem !important;
        }

        ::v-deep .el-drawer__close-btn {
            z-index: 33;
        }

        .layer-title {
            background-color: #fff;
            border-bottom: 1px solid #d8d7d5;
            border-top: 1px solid #d8d7d5;
            line-height: 50px;
            position: fixed;
            text-align: center;
            top: 50px;
            width: 100% !important;
            z-index: 1;
        }

        .mandatory,
        .Name,
        .Surname,
        .Address,
        .CareOf,
        .City,
        .countryField,
        .Phone,
        .Province,
        .Mail,
        .Mobile,
        .privacyDisclaimer,
        .buttonsRow {
            margin: 0 1.1875rem;
        }

        .buttonsRow {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 0 -3.125rem;

            button {
                padding: 1rem 3.125rem 0.9375rem;
                margin-bottom: 2rem;
            }
        }
    }
}
</style>

